import { put, call, takeEvery } from 'redux-saga/effects'
import authService from '../../services/auth.service'

function* login(action) {
	try {
		const { login, password } = action.payload
		yield call(authService.getCsrf)
		const loginRes = yield call(() => authService.login(login, password))

		console.log(loginRes, 'loginRes')

		// Проверяем, есть ли поле first_login в ответе
		if (!loginRes.data || typeof loginRes.data.first_login === 'undefined') {
			throw new Error('Ошибка: поле first_login отсутствует в ответе')
		}

		const firstLogin = loginRes.data.first_login

		console.log(firstLogin, 'first_login') // Выводим значение first_login в консоль

		if (loginRes.status === 200) {
			yield put({ type: 'FETCH_CURRENT_USER' })
			yield call(() => localStorage.setItem('authToken', loginRes.data.token))
			yield call(() => localStorage.setItem('expiry', loginRes.data.expiry))
			yield call(() => {
				window.location = firstLogin ? '/agreement' : '/'
			})
		}
	} catch (e) {
		console.error(e) // Выводим ошибку в консоль
		yield put({ type: 'SET_AUTH_ERROR', payload: 'Ошибка авторизации' })
	}
}

export default function* loginSaga() {
	yield takeEvery('AUTH_BY_LOGIN', login)
}
