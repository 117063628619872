import { memo } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogRow from './DialogRow'

const dialogRowsData = [
	{
		title: 'Автор',
		value: {
			left: 'Дмитриев А.С.',
			left1: 'Новак М.Ю.',
		},
	},
	{
		title: 'Правообладатель',
		value: {
			left: 'ООО "КАТМ"',
		},
	},
	{
		title: 'Сайт',
		value: {
			left: 'http://catm.tech',
		},
	},
	{
		title: 'Телефон',
		value: {
			left: '+7 (800) 101-24-86',
		},
	},
	{
		title: 'E-mail',
		value: {
			left: 'info@catm.tech',
		},
	},
	{
		title: 'Техническая поддрежка',
		value: {
			left: 'Cygenic',
			right: 'data@cygenic.tech',
		},
	},
]

const SystemInfoDialog = ({ onCloseSystemInfoDialog, isSystemInfoDialog }) => {
	return (
		<Dialog onClose={onCloseSystemInfoDialog} open={isSystemInfoDialog}>
			<div className='sNewPermit-popup'>
				<div className='system-info-dialog__logo'>
					<img src='/img/svg/logo.svg' alt='' />
				</div>
				{dialogRowsData.map(({ title, value }) => (
					<DialogRow key={title} title={title} value={value} />
				))}
				<button
					className='btn btn-outline-primary'
					onClick={onCloseSystemInfoDialog}
				>
					Закрыть
				</button>
			</div>
		</Dialog>
	)
}

export default memo(SystemInfoDialog)
