import { memo, useEffect, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../ui/Input/Input'
import Button from '../../ui/Button/Button'
import SystemInfoDialog from './components/SystemInfoDialog'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import './auth-page.css'

const schema = yup.object({
	loginOrPhone: yup.mixed().required(), //  строка, и число
	password: yup.string().required(),
})

const AuthPage = () => {
	const dispatch = useDispatch()
	const authError = useSelector(state => state.currentUser?.error)
	const {
		register,
		handleSubmit,
		reset,
		setError,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	})
	const [isSystemInfoDialog, setSystemInfoDialog] = useState(false)

	const onOpenSystemInfoDialog = useCallback(
		() => setSystemInfoDialog(true),
		[]
	)

	const onCloseSystemInfoDialog = useCallback(
		() => setSystemInfoDialog(false),
		[]
	)

	const onSubmit = useCallback(
		async ({ loginOrPhone, password }) => {
			try {
				// Определяем, является ли введенное значение логином или номером телефона
				const isPhone = /^\d+$/.test(loginOrPhone) // Проверяем, состоит ли значение только из цифр
				const login = isPhone ? parseInt(loginOrPhone, 10) : loginOrPhone // Если это номер телефона, преобразуем его в число

				dispatch({ type: 'AUTH_BY_LOGIN', payload: { login, password } })
			} catch (e) {
				reset()
				console.log(e)
			}
		},
		[dispatch, reset]
	)

	useEffect(() => {
		if (authError) {
			setError('loginOrPhone', { type: 'custom', message: 'custom error' })
			setError('password', { type: 'custom', message: 'custom error' })
		}
	}, [authError])

	return (
		<div className='main-wrapper'>
			<SystemInfoDialog
				onCloseSystemInfoDialog={onCloseSystemInfoDialog}
				isSystemInfoDialog={isSystemInfoDialog}
			/>
			<div className='sLogin' id='sLogin'>
				<div className='sLogin__wrap'>
					<div className='form-wrap'>
						<div className='sLogin__logo'>
							<img src='/img/svg/logo.svg' alt='' />
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Input
								register={register('loginOrPhone')}
								placeholder='Логин или номер телефона'
								type='text'
								inputStyle='authInput'
							/>

							<Input
								register={register('password')}
								placeholder='Пароль'
								type='password'
								inputStyle='authInput'
							/>
							{(errors.loginOrPhone?.message || errors.password?.message) && (
								<span style={{ color: 'var(--bs-danger)', fontSize: '12px' }}>
									Неверный Логин или Пароль
								</span>
							)}
							<Button type='submit' title={'Войти'} buttonStyle={'warning'} />
						</form>
					</div>
					<div
						className='sLogin__about system-button'
						onClick={onOpenSystemInfoDialog}
					>
						О системе
					</div>
					<div className='sLogin__bg'>
						<img src='/img/svg/login-bg.svg' alt='' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(AuthPage)
