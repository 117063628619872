const initialState = {
	error: null,
}

export const agreementReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_KEY_SUBMISSION_ERROR':
			return { ...state, error: action.payload }
		case 'KEY_SUBMISSION_SUCCESS':
			return { ...state, error: null }
		default:
			return state
	}
}
