import React from 'react'
import './style.css'

export default function LicenseNotification() {
	return (
		<div className='license-notification'>
			<div className='license-notification__wrapper'>
				<div className='license-notification__icon'>
					<svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
						<path d="M37.205 29.8163L23.22 7.40003C22.8816 6.85186 22.4089 6.39916 21.8466 6.08482C21.2843 5.77047 20.6511 5.60489 20.0069 5.60377C19.3627 5.60264 18.7289 5.766 18.1655 6.07838C17.6021 6.39076 17.1278 6.8418 16.7875 7.38878L2.77999 29.8425C2.4543 30.4137 2.28308 31.0599 2.2832 31.7175C2.28333 32.375 2.4548 33.0211 2.78071 33.5922C3.10662 34.1633 3.57572 34.6396 4.14178 34.9741C4.70783 35.3087 5.35129 35.4899 6.00874 35.5H33.9912C34.6511 35.4895 35.2968 35.3067 35.8642 34.9696C36.4315 34.6325 36.9009 34.153 37.2258 33.5785C37.5506 33.004 37.7196 32.3546 37.7159 31.6946C37.7123 31.0347 37.5361 30.3871 37.205 29.8163Z" fill="#FFD54F" />
						<path d="M18.8588 23.7801L18.5263 18.8101C18.4613 17.8101 18.1201 16.1938 18.8838 15.3813C19.4651 14.7563 20.8413 14.6488 21.2588 15.5151C21.6139 16.533 21.6924 17.6269 21.4863 18.6851L21.0413 23.8013C21.0222 24.2828 20.9168 24.757 20.7301 25.2013C20.6566 25.3451 20.5452 25.466 20.4079 25.551C20.2706 25.636 20.1127 25.6818 19.9513 25.6834C19.7898 25.6851 19.631 25.6426 19.492 25.5605C19.353 25.4783 19.2391 25.3598 19.1626 25.2176C18.9867 24.7573 18.8842 24.2722 18.8588 23.7801ZM20.0101 30.6088C19.6159 30.6066 19.2374 30.4543 18.9515 30.1829C18.6657 29.9114 18.494 29.5413 18.4714 29.1478C18.4487 28.7542 18.5769 28.3668 18.8298 28.0645C19.0827 27.7621 19.4413 27.5674 19.8326 27.5201C20.0428 27.4946 20.2561 27.5125 20.4591 27.5727C20.6622 27.6328 20.8507 27.734 21.0132 27.8698C21.1756 28.0057 21.3085 28.1734 21.4036 28.3626C21.4988 28.5518 21.5541 28.7585 21.5663 28.9699C21.5784 29.1813 21.5471 29.393 21.4743 29.5919C21.4014 29.7907 21.2886 29.9725 21.1428 30.1261C20.997 30.2797 20.8213 30.4017 20.6265 30.4848C20.4316 30.5678 20.2219 30.61 20.0101 30.6088Z" fill="#596C76" />
					</svg>
				</div>
				<div className='license-notification__text'>
					<div className='license-notification__title'>
						Лицензия просрочена
					</div>
					<div className='license-notification__caption'>
						Приложение работает в режиме просмотра
					</div>
				</div>
			</div>
		</div>
	)
}
