import { combineReducers } from 'redux'
import { currentUserReducer } from './currentUserReducer'
import { briefingsReducer } from './briefingsReducer'
import { permitsReducer } from './permitsReducer'
import { documentsReducer } from './documentsReducer'
import { agreementReducer } from './agreementReducer'

const rootReducer = combineReducers({
	currentUser: currentUserReducer,
	briefings: briefingsReducer,
	permits: permitsReducer,
	documents: documentsReducer,
	agreement: agreementReducer,
})

export default rootReducer
