import { put, call, takeEvery } from 'redux-saga/effects'
import authService from '../../services/auth.service'

function* submitKey(action) {
	try {
		const { key } = action.payload

		yield call(authService.getCsrf)

		const response = yield call(() => authService.submitKey(key))

		if (response.status === 200) {
			yield put({ type: 'KEY_SUBMISSION_SUCCESS', payload: response.data })

			yield call(() => {
				window.location = '/' // Переход на главную страницу после успешной отправки ключа
			})
		}
	} catch (e) {
		console.error('Error in submitKey saga:', e) // Выводим ошибку в консоль
		yield put({
			type: 'SET_KEY_SUBMISSION_ERROR',
			payload: 'Ошибка при отправке ключа',
		})
	}
}

export default function* agreementSaga() {
	yield takeEvery('SUBMIT_KEY', submitKey)
}
