import { memo, useEffect, useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import TopHat from "./TopHat";
import LeftMenu from "./LeftMenu";
import { getLicenseInfo } from "../../services/license.service";
import LicenseNotification from "../LicenseNotification/LicenseNotification";


const MenuWrapper = () => {
	const [license, setLicense] = useState({})
	const [licenseNotification, setLicenseNotification] = useState(false)

	const getLicenceData = useCallback(async (search) => {
		setLicense({});
		try {
			const { data: results } = await getLicenseInfo();
			setLicense(results);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}, [])

	useEffect(() => {
		getLicenceData();
	}, [])

	useEffect(() => {
		// console.log("Дата лицензии", license.date_expire);
		if (license.date_expire!=null) {
			let now = new Date();
			let dateLicense = new Date(license.date_expire);
			if (dateLicense > now) {
				setLicenseNotification(false);
			} else {
				setLicenseNotification(true);
			}
		} else {
			setLicenseNotification(true);
		}
	}, [license])

	return (
		<div className="main-wrapper">
			{licenseNotification && <LicenseNotification />}
			<TopHat />
			<div className="sMainBlock" id="sMainBlock">
				<LeftMenu />
				<Outlet />
			</div>
		</div>
	);
};

export default memo(MenuWrapper);
