import { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../ui/Input/Input'
import Button from '../../ui/Button/Button'
import './agreementPage.css'

const AgreementPage = () => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		clearErrors,
	} = useForm()
	const [isChecked, setIsChecked] = useState(false)
	const dispatch = useDispatch()
	const submissionError = useSelector(state => state.agreement?.error)

	useEffect(() => {
		// console.log('Submission error updated:', submissionError)
	}, [submissionError])

	const onSubmit = useCallback(
		data => {
			if (!isChecked) {
				setError('agree', {
					type: 'custom',
					message: 'Вы должны согласиться с обработкой персональных данных',
				})
				return
			}
			dispatch({ type: 'SUBMIT_KEY', payload: { key: data.key } })
		},
		[dispatch, isChecked, setError]
	)

	return (
		<div className='main-wrapper'>
			<div className='agreement-page'>
				<div className='agreement-form-wrap'>
					<div className='logo'>
						<img src='/img/svg/logo.svg' alt='Logo' />
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input
							register={register('key', {
								required: 'Поле обязательно для заполнения',
								minLength: {
									value: 8,
									message: 'Ключ должен содержать не менее 8 символов',
								},
							})}
							placeholder='Введите индивидуальный ключ'
							type='text'
							inputStyle='authInput'
						/>
						{errors.key && (
							<span style={{ color: 'var(--bs-danger)', fontSize: '12px' }}>
								{errors.key.message}
							</span>
						)}
						<div className='checkbox'>
							<input
								type='checkbox'
								id='agree'
								checked={isChecked}
								onChange={() => {
									clearErrors('agree')
									setIsChecked(!isChecked)
								}}
							/>
							<label htmlFor='agree'>
								Согласен с обработкой персональных данных
							</label>
						</div>
						{errors.agree && (
							<span style={{ color: 'var(--bs-danger)', fontSize: '12px' }}>
								{errors.agree.message}
							</span>
						)}
						{submissionError && (
							<span style={{ color: 'var(--bs-danger)', fontSize: '12px' }}>
								{submissionError}
							</span>
						)}
						<div className='scrollable-agreement'>
							{/* Блок с текстом согласия */}
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
								in reprehenderit in voluptate velit esse cillum dolore eu fugiat
								nulla pariatur. Excepteur sint occaecat cupidatat non proident,
								sunt in culpa qui officia deserunt mollit anim id est laborum.
							</p>
						</div>
						<div className='btn-block'>
							<Button type='submit' title='Войти' buttonStyle='warning' />
						</div>
					</form>
				</div>
				<div className='sLogin__bg'>
					<img src='/img/svg/login-bg.svg' alt='Background' />
				</div>
			</div>
		</div>
	)
}

export default AgreementPage
